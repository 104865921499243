select {
  width: 100%;
  height: 50px;
}

select,
option {
  color: #fff;
  padding: 5px 25px;
  border-radius: 8px;
  background: var(--iq-body-bg);
  border: 1px solid #404043;
}

.ws-bg-select-plan:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 50%, rgba(83, 100, 141, 0) 100%) !important;
}

.ws-bg-credit-card:before {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 100%, rgba(83, 100, 141, 0) 100%) !important;
}

.isMonthly {
  display: flex;

  align-items: center;
  width: 210px;
  height: 42px;
  margin: 20px auto;
  border-radius: 21px;
  background-color: #272c32;
}

.isMonthly > div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.isMonthlyActive {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 108px;
  height: 42px;
  border-radius: 21px;
  background-color: #be161a;
}

.ws-card-left {
  border-radius: 10px;
  border: solid 1px #272c32;
  padding: 20px 100px 30px 80px;
  background: #000;
}

.ws-card-right {
  border-radius: 10px;
  padding: 50px 100px 60px 80px;
  box-shadow: 0 32px 64px 0 rgba(57, 20, 0, 0.04);
  background-color: #14161a;
  border: solid 1px #14161a;
}

.ws-circle {
  background: #be161a;
  padding: 2px;
  border-radius: 50%;
  margin-right: 10px;
}
.your-plan-text {
  color: #fff;
  text-align: center;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: 10px;
}

@media (max-width: 576px) {
  .ws-card-left {
    padding: 10px;
  }

  .ws-card-right {
    padding: 20px;
  }
  .your-plan-text {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .ws-card-left {
    padding: 20px 20px 30px 20px;
  }

  .ws-card-right {
    padding: 20px 20px 30px 20px;
  }
}

.plan-visible img {
  min-width: 16px !important;
}
