.bg-img {
  position: absolute;
  bottom: 0px;
  width: 240px;
  height: 140px;
  z-index: 999;
  background-image: linear-gradient(180deg, rgba(19, 21, 23, 0) 68%, #000 128%);
}

.disabled {
  display: block;
}

.slick-bg111 {
  background-position-y: 80px !important;
}

.slick-bg-not-login {
  background-position-y: 80px !important;
}

.slick-max-bg {
  /* height: auto !important; */
  overflow: hidden;
}

.slick-max-bg > img {
  visibility: hidden;
}

.animation-bg {
  display: block;
  width: 300vw;
  height: auto;

  padding-bottom: 30%;
  position: relative;
  top: 80px;
  margin-bottom: 90px;
  background-repeat: repeat-x !important;
  background-size: 200vw 100% !important;
  animation: MOVE-BG 50s linear infinite;
}

@media (max-width: 576px) {
  .disabled {
    display: none;
  }

  .slick-max-bg {
    height: 260px !important;
  }

  .slick-bg111 {
    background-position-y: 10px !important;
    background-position-x: -850px !important;
  }

  .animation-bg {
    min-height: 240px;
    padding-bottom: 40%;
    background-position-y: 0px !important;
    top: 18px !important;
    margin-bottom: 30px;
    background-size: 400vw 100% !important;

    /* background-size: 130px 95% !important;
    height: 260px; */
  }
  .mobile-background {
    position: absolute;
    top: 80px !important;
    width: 100%;
    min-height: 240px;
    background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 79.5%, #000 100%) !important;
  }
}

@media (max-width: 990px) {
  .slick-bg111 {
    background-position-y: 20px !important;
  }

  .slick-max-bg {
    height: 45vh;
  }

  .animation-bg {
    padding-bottom: 30%;
    background-position-y: 0px !important;
    top: 20px !important;
    margin-bottom: 40px;

    /* background-size: 300vw 95% !important; */
  }
}

.ws-card-teachers {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ws-card-teachers-bg {
  position: absolute;
  width: 50%;
  height: 60%;
  min-width: 200px;
  min-height: 300px;
  background-color: #14161a;
  border-top-left-radius: 60px;
  border-bottom-right-radius: 60px;
}

.ws-card-teachers-bg::after {
  content: '”';
  font-size: 70px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #be161a;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;

  position: absolute;
  top: -10px;
  left: -10px;
}

.ws-ls-card-header {
  width: 95%;
  top: 15px;
}

.ws-ls-card-header-left {
  width: auto;
  padding: 5px 10px;
  margin-left: 20px;
  border-radius: 32px;
  backdrop-filter: blur(6.4px);
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 99;
}

.slick-bg11 {
  border-radius: 10px;
}

.slick-bg11:before {
  background: linear-gradient(90deg, #14161a 0%, rgba(20, 20, 20, 1) 47%, rgba(83, 100, 141, 0.1) 100%) !important;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
}

.slick-bg111:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 0.9) 15%, rgba(83, 100, 141, 0) 70%) !important;
}

.modal-90w {
  width: 100vh !important;
}

/* .animation-bg:before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, .9) 15%, rgba(83, 100, 141, 0) 70%) !important;
} */

@keyframes MOVE-BG {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -3000px 0;
  }
}

.bg-shadow {
  position: absolute;
  top: 0px;
  background: linear-gradient(-90deg, #14161a 0%, rgba(20, 20, 20, 1) 16%, rgba(83, 100, 141, 0.1) 40%) !important;

  width: 100% !important;
  height: 300px !important;
  z-index: 9;
  border-radius: 10px;
}
