.bg-title {
    background-size: 70% !important;

    background-position: 100% 100% !important;
}

.password-eye {
    position: absolute;
    right: 20px !important;
    top: 11px;
}