.imageBox1 {
    position: relative;
}

.teacherLogo {
    left: 25%;
    top: 20%;
    width: 110px;
}

.heart {
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 333;
}

.cardBg {
    border-radius: 10px;
    border: solid 1px #272c32;
    background-color: #14161a;
    position: relative;
}

.footer {
    width: 90%;
    margin: 10px auto;
}

.progressWrapper {
    width: 75%;
    position: relative;
    align-items: center;

    margin: 20px 0px;
    margin-top: 25px;
}

.time {
    position: absolute;
    bottom: -5px;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;



}

.progress {

    width: 90%;
    margin-left: 50px;
}

.progressLine {
    height: 10px;
    background: #be161a;
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
}

.progressLine1 {
    height: 10px;
    background: #272c32;
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
}

.teacherBg {
    min-width: 200px;
    min-height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-radius: 10px;

}