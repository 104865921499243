.wsAbout {
    width: 100%;
    height: 405px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
}
.wsAboutList{
    height: 310px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.wsPlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-image: linear-gradient(to bottom, rgba(19, 21, 23, 0) 17%, rgba(0, 0, 0, 0.7));
}
.wsPlayIslogin{

    background-image:
    linear-gradient(black, black);
  background-size: cover;
  background-blend-mode: saturation;
  filter: grayscale(100%);
   
}

.wsPlayIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8.6985px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons{
 
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.addCard{
    max-height: 280px;
    overflow-y: scroll;
    padding: 15px 25px;
    border-radius: 10px;
    border: solid 1px #272c32;
    background-color: #272c32;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addCard1{
    max-height: 280px;
    overflow-y: scroll;
    padding: 15px 25px;
    border-radius: 10px;
    border: solid 1px #272c32;
    background-color: #272c32;

}
.addCard>img{
    width: 24px;
    height: 24px;
}
.createText{
    font-family: MartelSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}

.wsNoteCart{
  
    padding-bottom: 20px;
    border-bottom: 1px solid grey;
}


