/* .lessonlist .swiper-slide{
    filter: blur(1px) !important;
} 

.lessonlist .swiper-slide-active{
    filter: blur(0px) !important;
    transform: scale(1.01);
    border: 1px solid #000;
} */
.tuby video {
    width: 100%;
    height: auto;
    background-color: #000;

}

.trending-pills-watch {
    background: #14161a !important;
}

.tuby-container {
    background-color: #000 !important;
    z-index: 2 !important;
}

#movieshow1 {
    background: #14161a !important;
    padding-bottom: 50px !important;
}


#movieshow1 .swiper-wrapper {
    transition-duration: 0ms;
    transform: translate3d(100px, 0px, 0px);
}

.lessons-slide-btn {
    position: absolute;
    width: 80%;
    top: 45%;
    left: 10%;
    right: 10%;
    display: flex;
    align-items: center;

}

@media (max-width:576px) {
    .lessons-slide-btn {
        display: none;
    }
}

@media (max-width:768px) {
    .lessons-slide-btn {
        display: none;
    }
}

@media (min-width:992px) {
    .lessons-slide-btn {
        top: 40%;
    }
}

@media (min-width:1300px) {
    .lessons-slide-btn {}
}

.lessons-slide-btn>.swiper-button-prev,
.swiper-button-next {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(8.6985px) !important;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lessons-slide-btn .btn-icon {
    font-size: 40px;
    font-weight: 500;
}

.nav-pills .nav-link.active {
    background: #14161a !important;
    overflow: visible !important;
}

.trending-pills.nav-pills .nav-item a {
    position: relative;
    overflow: visible !important;
    border-radius: 0;
    text-transform: capitalize;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.trending-pills.nav-pills .nav-item a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 0;
    height: 3px;
    background: var(--iq-primary);
    opacity: 0;
    -webkit-transition: all 0.8s linear;
    -moz-transition: all 0.8s linear;
    -o-transition: all 0.8s linear;
    transition: all 0.8s linear;
}

.trending-pills.nav-pills .nav-item a:before {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(83, 100, 141, 0) 0%, rgb(218, 64, 60, 0.3) 85%);
    content: '';
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    -o-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.trending-pills.nav-pills .nav-item .nav-link.active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px !important;
    width: 100%;
    height: 3px;
    background: var(--iq-primary) !important;
    opacity: 1;
    transition: all 0.8s linear;
}

.active {
    color: var(--iq-primary) !important;
}

.trending-pills {
    position: relative;
    padding-bottom: 20px;
    padding-left: 0px;

}

.ws-line {
    mix-blend-mode: normal;
    opacity: 0.2;
    border-bottom: 1px solid #fff;
}

video {
    object-fit: cover !important;

}

.tuby-volume-slider {
    height: 4px;
    background: #ffffff91;
    outline: none;
    border-radius: 1px;
    appearance: none !important;
    width: 52px;
    z-index: 1;
    cursor: pointer;
    margin: 0;
}

input[type="range" i] {
    appearance: auto;
    cursor: default;
    color: -internal-light-dark(rgb(16, 16, 16), rgb(255, 255, 255)) !important;
    padding: initial;
    border: initial;

}

.shows-img {
    background-repeat: no-repeat;
    background-size: 115% 115%;
    background-position: center;
}


.tilt-in-fwd-tr {
    -webkit-animation: tilt-in-fwd-tr .6s cubic-bezier(.25, .46, .45, .94) both;
    animation: tilt-in-fwd-tr .6s cubic-bezier(.25, .46, .45, .94) both
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-11 18:55:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1
    }
}

@keyframes tilt-in-fwd-tr {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1
    }
}