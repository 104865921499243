.container {
    width: 98%;
    margin: 0px auto;
    padding: 20px 30px;
    height: auto;
    min-height: 104px;
    border-radius: 10px;
    background: #14161a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -50px;
    z-index: 9;

}

.text {
    height: auto;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #fff;
}

.inputSearch {
    background: #14161A !important;
    border: 1px solid #272C32 !important;
    border-radius: 8px !important;
    height: 65px !important;
    color: #fff !important;

}

.inputSearch:focus {


    background: #14161A;
    border: 1px solid #272C32;
    border-radius: 8px;

}

.line {
    width: 2px;
    height: 52px;
    background: rgba(255, 255, 255, 0.0974556);
}

.myClass {
    padding-left: 0px;
    border: none !important;
    height: 40px;
    background: #14161a !important;
}

.myClass option {
    background: #000 !important;
    color: #fff !important;

}

.label {
    padding-left: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */


    color: rgba(255, 255, 255, 0.496479);
}

.wSelectBox {
    width: 60%;
}



@media (max-width:992px) {
    .container {
        justify-content: center;
    }

    .wSelectBox {
        width: 100%;
    }

    .line {
        display: none;
    }
}

@media (min-width:1300px) {
    .wSelectBox {
        width: 60%;
    }
}