select {
    width: 100%;
    height: 50px;
}

select,
option {
    color: #fff;
    padding: 5px 25px;
    border-radius: 8px;
    background: var(--iq-body-bg);
    border: 1px solid #404043;
}

.ws-bg-select-plan:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.99) 50%, rgba(83, 100, 141, 0) 100%) !important;
}

.ws-bg-credit-card:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 100%, rgba(83, 100, 141, 0) 100%) !important;
}

.isMonthly {
    display: flex;

    align-items: center;
    width: 210px;
    height: 42px;
    margin: 20px auto;
    border-radius: 21px;
    background-color: #272c32;
}

.isMonthly>div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.isMonthlyActive {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 108px;
    height: 42px;
    border-radius: 21px;
    background-color: #be161a;
}

.ws-card-left {
    border-radius: 10px;
    border: solid 1px #272c32;
    padding: 20px 100px 30px 80px;
    background: #000;
}

.ws-card-right {
    border-radius: 10px;
    padding: 50px 100px 60px 80px;
    box-shadow: 0 32px 64px 0 rgba(57, 20, 0, 0.04);
    background-color: #14161a;
}

.ws-circle {
    margin-right: 10px;
}

.form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.2) !important;
}

.styleSelect {
    width: 100%;
}

.password-eye1 {
    position: absolute;
    right: 30px;
    top: 11px;
}

.plan-visible img {
    min-width: 16px !important;
}

.form-control.valid-code {
    border-color: #4caf50 !important;
    position: relative;
}

/* Geçersiz kod (invalid-code) */
.kod .invalid-code {
    border-color: #f44336 !important;
    position: relative !important;
}

.kod .invalid-code-icon {
    position: absolute;
    right: 30px;
    top: 45px;
}

.kod .valid-code-icon {
    position: absolute;
    right: 30px;
    top: 45px;
}

.kod .fa-exclamation-circle {
    color: #f44336 !important;
    font-size: 1.2em;
    /* İkonun boyutunu ayarlayın */
}

.kod .fa-check-circle {
    color: #4caf50 !important;
    font-size: 1.2em;
    /* İkonun boyutunu ayarlayın */
}

.card-preview {
    position: relative;
    width: 100%;
    height: 200px;
    background: url('../../assets/images/card/card_bg_img.jpg') no-repeat center/cover;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.card-preview .number,
.card-preview .name,
.card-preview .expiry {
    position: absolute;
    margin: 0;
    font-weight: bold;
    color: #000;
}

.card-preview .number {
    top: 80px;
    left: 20px;
    font-size: 18px;
}

.card-preview .name {
    bottom: 40px;
    left: 20px;
    font-size: 16px;
    text-transform: uppercase;
}

.card-preview .expiry {
    bottom: 40px;
    right: 20px;
    font-size: 16px;
}

.gift-card {
    overflow: hidden;
    border: 1px solid #c9c9ca;
    min-height: 200px;
}

.gift-card-image {
    position: relative;
    overflow: hidden;
}

.gift-card-image img {
    width: 100%;
    height: auto;
}

.gift-card-image-overlay {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 1) 60%, rgba(83, 100, 141, 0) 100%) !important;
    z-index: 2;
    opacity: 1;
}

.gift-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
}

.rounded-3 {
    border-radius: 1.2rem !important;
}

.gift-card-price {
    padding-top: 1rem;
}

.gift-card-price-pre-text {
    color: #fff;
    font-size: 1rem;
    font-weight: normal;
}

.gift-card-price-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
}

.gift-card-send-option input[type='radio'] {
    --s: 2em;
    /* control the size */
    height: var(--s);
    width: var(--s);
    border: calc(var(--s) / 15) solid #939393;
    padding: calc(var(--s) / 8) !important;
    background: radial-gradient(farthest-side, var(--iq-primary) 100%, #0000) 50%/0 0 no-repeat content-box;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    transition: 0.3s;
}

.gift-card-send-option input[type='radio']:checked {
    border: calc(var(--s) / 15) solid #939393 !important;
    border-color: var(--iq-primary);
    background-size: 80% 80%;
}

.gift-card-send-option input[type='radio']:disabled {
    background: linear-gradient(#939393 0 0) 50%/100% 20% no-repeat content-box;
    opacity: 0.5;
    cursor: not-allowed;
}

.gift-card-date-picker {
    gap: 1rem;
}

.gift-card-time-picker .react-datepicker-time__header {
    width: 100%;
    color: #be161a;
}

.gift-card-time-picker .react-datepicker__time-list-item {
    background-color: #000 !important;
    color: #d1cfcf !important;
}

.gift-card-image-container {
    z-index: 1;
    height: auto;
    position: relative;
}

.gift-card-image-text {
    width: 100%;
    padding: 7px;
    text-align: center;
    left: 0;
    position: absolute;
    margin: auto;
}


.gift-card-image-sender {
    font-style: italic;
    font-size: 1rem;
    margin-top: 1%;
}

.gift-card-image-note {
    font-size: 1.1rem;
    width: 100%;
    margin: auto;
    text-align: center;
    max-width: 80% !important;
    word-wrap: break-word;
}

@media (max-width: 780px) {
    .gift-card-image-note {
        font-size: 0.8rem;
    }

    .gift-card {
        flex-direction: column !important;
    }

    .gift-card-info {
        align-items: center !important;
    }

    .gift-card-price-text {
        font-size: 1rem;
    }

    .gift-card-image-overlay {
        top: 50% !important;
        left: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 1) 60%, rgba(83, 100, 141, 0) 100%) !important;
    }
}

@media (max-width: 420px) {
    .btn {
        padding: 0.5rem 1rem !important;
        font-size: 0.8rem !important;
    }

    .gift-card h4 {
        font-size: 1.4rem !important;
    }
}