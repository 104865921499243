.container {
    width: 90%;
    margin: 0px auto;
    padding: 10px 50px;
    height: auto;
    min-height: 164px;
    border-radius: 10px;
    background: #14161a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

}

.text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 32px;
}

.text1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
}

.price,
.plan {
    height: 21px;
    overflow: hidden;
}

.price_item {

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;

}

.price_item {
    display: block;
    animation: spin_price 4s ease-in-out infinite;
}

@keyframes spin_price {
    0% {
        transform: translateY(-100%);
    }

    25% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(0%);
    }

    75% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}



@media (max-width:576px) {
    .container {
        padding: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

}

@media (max-width:768px) {
    .container {
        padding: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }


}