.wsNateCard {
  width: 100%;
  /* height: 135px; */
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  border: solid 1px #272c32;
  background-color: #14161a;
  overflow: hidden;
  position: relative;
}

.Mask {
  max-width: 130px;
  max-height: 85px;
  margin: 25px 20px;
  border-radius: 10px;
}

.notesCount {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #be161a;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  padding-top: 18px;
  padding-right: 5px;
  font-size: 17px;
}

.addCard1 {
  max-height: 280px;
  overflow-y: scroll;
  padding: 15px 25px;
  border-radius: 10px;
  border: solid 1px #272c32;
  background-color: #272c32;

}

.wsNoteCart {

  padding-bottom: 20px;

}

.addCard {
  width: 100%;
}

.addCard44 {
  padding-top: 10px;
  font-size: 14px;
}

.time {
  width: 110px;
  height: 30px;
  background: #BE161A;
  border-radius: 32px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.createText {
  background: #272C32;
  border-radius: 10px;
  padding: 20px;
}

.scroll {
  max-height: 500px;
  overflow-y: scroll;
}

@media (max-width:768px) {
  .wsNateCard {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px;
  }
}