.tab{
	padding: 10px 20px;
	cursor: pointer;
	color: #bbb; /* Metin rengi gri */
	font-weight: normal;
	position: relative;
}

.tab.active {
    color: #fff; /* Seçili sekmenin metin rengi beyaz */
    font-weight: bold;
  }
  
  .tab.active::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: #be161a; /* Alt çizgi rengi kırmızı */
  }