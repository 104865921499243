.profiles-w {
    width: 100%;
}

.sign-in-page1 {
    height: 100vh;
}

.ws-item {
    border-radius: 10px;
    min-height: 190px;
    min-width: 190px;
    max-width: 190px;
    max-height: 190px;
    margin: 0px auto;
    border: solid 2px #272c32;
    background-color: #14161a;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.ws-item-menu {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}

/*Emoji*/
.emoji {
    position: relative;
}

.ws-select-icon {
    position: absolute;
    top: 34px;
    right: 20px;

}

.ws-select-icon>i {
    width: 30px;
}

.ws-emoji-card {
    position: absolute;
    top: 30px;
    right: -335px;
    width: 307px;
    max-height: 430px;
    background-color: #14161a;
    padding: 15px 0px;

}

.ws-emoji-card-header {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 0px 25px;
    margin-bottom: 5px;
}

.ws-emoji-card-body {
    padding: 10px 25px;
    margin-bottom: 10px;
}

@media (max-width:479px) {


    .ws-emoji-card {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 307px;
        max-height: 430px;
        background-color: #14161a;
        z-index: 99;

    }

    .profiles-w {
        width: 100%;
    }
}

@media (max-width:576px) {
    .sign-in-page1 {
        height: auto !important;
    }

    .ws-emoji-card {
        position: absolute;
        top: 80px;
        left: 0px;
        width: 307px;
        max-height: 430px;
        background-color: #14161a;
        z-index: 99;

    }

    .profiles-w {
        width: 100%;
    }
}

@media (max-width:1024px) {
    .ws-emoji-card {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 307px;
        max-height: 430px;
        background-color: #14161a;
        z-index: 99;

    }

    .profiles-w {
        width: 90%;
    }
}




/* edit */
.ws-emoji-edit-card {
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 0px;
}