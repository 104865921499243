.ws-category-list-top {
  width: 100%;
  height: 64px;
  border-radius: 16px;
  backdrop-filter: blur(6.4px);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws-category-list-top > span {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.wsAnswerCardImg {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a;
  color: #fff;
  text-align: left;
  padding: 0px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-repeat: no-repeat !important;
  background-size: 0% 100%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s ease;
}
