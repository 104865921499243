.ws-category-list-top{
    width: 100%;
    height: 64px;
    border-radius: 16px;
    backdrop-filter: blur(6.4px);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws-category-list-top>span{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}