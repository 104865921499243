.wsCardHeaderLeft {
    position: absolute;
    border-radius: 32px;
    -webkit-backdrop-filter: blur(6.4px);
    backdrop-filter: blur(6.4px);
    background-color: rgba(255, 255, 255, 0.1);
    top: 15px;
    left: 20px;
    padding: 5px 10px;
    z-index: 99;
}

.progressWrapper {
    width: 70%;
    position: absolute;
    bottom: 30px;
    align-items: center;
    z-index: 99;
    justify-content: center;
}

.time {
    position: absolute;
    bottom: -5px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.progress {
    width: 85%;
    margin-left: 65px;
}

.progressLine {
    height: 10px;
    background: #be161a;
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
}

.progressLine1 {
    height: 10px;
    background: #272c32;
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
}

.wsFooter {
    position: absolute;
    z-index: 999;
    bottom: -2%;
    height: 50%;
    width: 100%;
    max-width: 100%;
    transition: transform .8s ease-out 100ms
}

.btnPlay {
    width: 80%;
    margin-left: 10%;
    position: absolute;
    bottom: -100px;
}

.wsCard:hover>.wsFooter {
    transform: translateY(-150px);

    transition: .7s ease;
    cursor: pointer;
    z-index: 999;
}


@media (max-width:400px) {
    .progressWrapper {
        width: 55%;
    }

    .wsCardHeaderLeft {
        max-width: 100px !important;
        overflow: hidden;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width:576px) {
    .progressWrapper {
        width: 55%;
    }
}

@media (max-width:768px) {}

@media (max-width:992px) {}

@media (max-width:3000px) {}