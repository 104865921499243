.we-header-title-modal {}

.path {
    border: 1px solid #C3CAD9;
    border-radius: 8px;
    color: #FFFFFF;

    mix-blend-mode: normal;
    opacity: 0.5;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-copy{
    width: 90%;
    margin: 0px auto;
}