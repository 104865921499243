 .displayBlock{
    background: #000 !important;
    z-index: 99;
}
.ptionContainer{
    background: #000 !important;
}
.search-wrapper{
    position: relative !important;
    display: block !important;
    height: auto !important;
  
}
.searchWrapper {
    border: none !important;
    border-radius: 4px;
    min-height: 22px;
    padding: 0 !important;
}
.multi > .searchBox {
    width: 50px !important;

}
.multi > input, input[type=email], input[type=password], input[type=search], input[type=text], input[type=tel], textarea {
    background: var(--iq-body-bg) !important;
    border: 1px solid #404043 !important; 
    border-radius: 0;
    color: var(--iq-body-text);
    height: 48px;
    line-height: 48px;
    padding: 0 15px;
    transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    width: 100%;
}

.dropzone {
    width: 100%;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
    background: #000;
    border: 1px solid #404043;
    border-radius: 0;
    position: relative;
    display: flex;
    align-items: center;
  }