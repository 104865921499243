.wsFooterCard {
    border-radius: 10px;
    background-color: #14161a;
    padding: 90px;
}

.wsContactCard {
    border-radius: 10px;
    -webkit-backdrop-filter: blur(20px) !important;
    backdrop-filter: blur(20px) !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    padding: 50px;
}

@media (max-width:576px) {
    .wsFooterCard {
        padding: 10px;
    }
}

@media (max-width:768px) {
    .wsFooterCard {
        padding: 15px;
    }
}