.menu-page-right {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #14161a;

    color: #fff;
    z-index: 1999999;
    transition: .3 ease-in;
}

.menu-page-right .menu-page-right-header {
    padding: 0 20px;
    display: flex;
    justify-content: end;
    align-items: center;

    height: 60px;
    border-bottom: 1px solid #fff;
}

.menu-page-right .menu-page-body {
    padding: 0 30px;
    text-align: left;
}