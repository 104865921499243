.wsAbout {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
}

.wsNextName {
    width: auto;
    height: auto;
    position: absolute;
    right: 155px;
    bottom: 75px;
    padding: 8px 25px;
    background: #fff;
    color: black;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    backdrop-filter: blur(10.6985px) !important;
    opacity: 0.7 !important;
}

.wsNext {
    width: 179px;
    height: auto;
    position: absolute;
    right: 15px;
    bottom: 75px;
    padding: 8px 25px;
    /* background: #fff; */
    color: black;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 10px;
    /* background-color: white; */
    background: linear-gradient(to right, white 50%, gray 50%);
    background-size: 200% 100%;
    background-position: 100% 0%;
    animation: changeBackground 10s linear !important;

}

@keyframes changeBackground {
    from {
        background-position: 100% 0%;
    }

    to {
        background-position: 0% 0%;
    }
}

.wsAboutList {
    height: 310px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.wsPlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-image: linear-gradient(to bottom, rgba(19, 21, 23, 0) 17%, rgba(0, 0, 0, 0.7));
}

.wsPlayIslogin {

    background-image:
        linear-gradient(black, black);
    background-size: cover;
    background-blend-mode: saturation;
    filter: grayscale(100%);

}

.wsPlayIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8.6985px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons {

    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.addCard {
    max-height: 280px;
    overflow-y: scroll;
    padding: 15px 25px;
    border-radius: 10px;
    border: solid 1px #272c32;
    background-color: #272c32;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addCard1 {
    max-height: 280px;
    overflow-y: scroll;
    padding: 15px 25px;
    border-radius: 10px;
    border: solid 1px #272c32;
    background-color: #272c32;

}

.addCard>img {
    width: 24px;
    height: 24px;
}

.createText {

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.wsNoteCart {

    padding-bottom: 20px;
    border-bottom: 1px solid grey;
}

.addCard44 {
    padding-top: 10px;
    font-size: 14px;
}


.category {
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(6.4px);
    backdrop-filter: blur(6.4px);
    background-color: #be161a;
    z-index: 99;
}