.wsBtn {
  position: absolute;
  right: 50px;
  bottom: 0px;
}

@media (max-width: 575px) {
  .wsBtn {
    width: 85%;
    left: 7.5%;
  }
}
