.line {
    position: relative;
    width: 130%;
    mix-blend-mode: normal;
    opacity: 0.2;
    border: 1px solid #FFFFFF;
    margin: 30px auto;
    left: -30px;
}

.date {
    position: absolute;
    font-size: 11px;
    font-style: italic;
    top: 31%;
    right: 3%;
    z-index: 11;
    color: #5A585A;
}

.attended {
    width: 40%;
    position: absolute;
    top: 39%;
    left: 30%;
    z-index: 11;
    display: flex;
    flex-direction: column;
}

.name {
    font-size: 30px;
    font-weight: bolder;
    z-index: 11;
    color: #E71D26;
    text-transform: capitalize;
}

.oturum {

    font-size: 11px;
    font-style: italic;
    color: #5A585A;
}

.certificateName {
    font-size: 11px;
    font-style: italic;
    color: #5A585A;
}

.certificate_number {
    position: absolute;
    font-size: 11px;
    font-style: italic;
    bottom: 5%;
    left: 30%;
    z-index: 11;
    color: #5A585A;
}