.wsAnswerCard{
    height: 82px;
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 0% 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    border:1px solid #272c32;
    color: #fff;
    text-align: left;
    padding: 30px 10px;
    border-radius: 10px;

    background-size: 30% 100% !important;
    background-color: #14161A ;
}
