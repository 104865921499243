.article-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4.5rem;

    /* or 114% */

    text-align: center;
    width: 80%;
    margin: 0px auto;

}

.article-sub-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-align: center !important;
    width: 50% !important;
    margin: 0px auto;
    color: #FFFFFF;
}

.slick-bg111:before {
    background: linear-gradient(180deg, rgba(19, 21, 23, 0.0001) 13.05%, #000000 91.83%) !important;
}

.slick-bg111 {
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ws-blog {
    background: #14161A;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.0965523);
    border-radius: 6px;
}

.blog-content>p {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.ws-most-device {

    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.0965523);
    border-radius: 6px;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    background-position: right !important;
    background-repeat: no-repeat !important;
    background-size: 50% 100% !important;


}

.ws-most-device:before {

    background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(83, 100, 141, 0) 100%) !important;
    width: 100%;
    z-index: -1;

}

.ws-most-device>.right-block {
    width: 50%;
    background-size: cover;
}

.ws-most-device>.left-block {
    width: 50%;
}

.ws-most-device>h1 {
    font-weight: 700;
}

.ws-article-left {
    background: #14161A !important;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.0965523);
    border-radius: 6px;
    overflow: hidden;
    max-height: 845px;
   
}

.ws-article-left .widget {
    background: #14161A !important;
}

.last-item {
    position: relative;
    left: -5%;
    padding: 0px 10%;
    background: #BE161A !important;
    width: 120%;
    height: 150%;

}

.last-item .widget {
    background: #BE161A !important;

}

.last-item .iq-widget-menu {
    width: 90%;
    margin: 0px auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.bg-title44 {
    padding: 70px 0px;
}

.menu-horizontal{
    transform: rotate(90deg);
}


/*Single Page */
.single-container{
    background: #14161A;
box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
border-radius: 10px;
}
.single-avatar{
    position: absolute;
    top: -100px;
    z-index: 99;
}
.tag-cloud-link{
    border-radius: 32px !important;
    color: #FFFFFF;
    
}

.form-input{
    position: relative;
}
.form-input i{
    position: absolute;
    right: 20px;
    font-size: 25px;
    bottom: 30%;
    color: #FFFFFF !important;
}
.FormControl:focus{
    background-color: transparent;
}
textarea{
    background: #14161A !important;
   
    color: #FFFFFF !important;
}

.swiper-btn{
    width: 200px;
    position: absolute;
    bottom: 80px;
    right: 50px;
}