.h1 {
    color: #be161a;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.p {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    margin: 0;
}

.checkmark {
    color: #be161a;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.card {
    height: 80vh;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 30px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 auto;
    border: none;
}