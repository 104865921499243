.video-madol-page {
  width: 100%;
  background: #000;

}

.video-madol-page .modal {
  width: 100% !important;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #000;
}

.video-madol-page .modalDialog {
  position: fixed;
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
}

.video-madol-page .modalContent {
  width: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 0;
  box-shadow: none;
}



.video-madol-page .modal-title {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}

.video-madol-page .modal-body {

  width: 100% !important;
  height: 100vh !important;
  position: absolute;
  top: 0px;
  bottom: 60px;

  font-weight: 300;
  overflow: hidden !important;
}

.video-madol-page .video-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.video-madol-page .video {
  max-width: 900px !important;
  max-height: 500px !important;
}


.video-madol-page .video-header {
  height: auto;
  position: relative;

}
@media(min-width: 900px) {
  .video-madol-page .video-header {
    margin-bottom: 40px;
  }

}

.video-madol-page .model-close-icon {
  position: absolute;
}

@media (max-width:768px) {
  .video-madol-page .model-close-icon {
    position: fixed;
  }


}

.tuby-controls-right button[data-tuby-tooltips="Settings"] {
  display: none;
}