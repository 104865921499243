.avatar {
    border-radius: 50%;
}


/* @media (max-width:576px) {
    .navbar-collapse {
        height: 100vh;
        width: 100vw;
    }
} */