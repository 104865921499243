.wsAbout {
    width: 150px;
}



.line {
    position: relative;
    width: 130%;
    mix-blend-mode: normal;
    opacity: 0.2;
    border: 1px solid #FFFFFF;
    margin: 30px auto;
    left: -30px;
}



@media (max-width:768px) {
    .wsAbout {
        width: 100px;
    }

    .wsAbout img {
        width: 100px;
        height: 100px !important;
        object-fit: cover;
        object-position: 0px -10px;
    }
}