
.footer-top{
    background: #000 !important;
}
footer{
    background: #000 !important;
}
.footer-list>ul{
    margin: 0;
    padding: 0;
    list-style:none;
    text-decoration: none;
}
.footer-list>ul>li{
    text-decoration: none;
    margin: 0 13px 15px 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
}

.footer-logo{
    width: 135px;
}