.styleSelect select {
  display: block;
  width: 100%;
  font-size: 1em;
  border: 1px solid #333;
  font-family: inherit;
  /** for the dropdown indicator */
  appearance: none;
  background-image: url("../../../assets/icons/arrowdown.svg");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.4em;


}

.styleSelect {
  width: 100%;
}