.imageBox1{
    position: relative;
    min-height: 290px !important;
}
.heart{
    position: absolute;
    right:5px;
    top:13px;
    z-index: 333;
    display: flex;
}
.wsCardMenuIcon{
    position: absolute !important;
    top: 10px;
    right: 10px;
    z-index: 333;
}
.teacherBg{

    /* background-position: 0px   !important; */
    background-repeat: no-repeat;
    background-size: cover !important;
    border-radius: 10px;
    position: relative;

}
.teacherBg img{
    
    border-radius: 10px;

}