.wsAnswerCard {
    height: 82px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #14161A;
    border: 1px solid #272c32;
    color: #fff;
    text-align: left;
    padding: 30px 66px 30px 30px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.wsAnswerCardImg {
    height: 80px;
    width: 100%;
    display: flex;

    align-items: center;
    background-color: #14161A !important;
    border: 1px solid #272c32;
    color: #fff;
    text-align: left;
    
    padding-left: 20px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat !important;
    background-size: 0% 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: 0.5s ease;

 
}
.wsAnswerCardImg:hover{
    padding-left: 20% !important;
    background-size: 30% 100% !important;
}
.wsActiveCard {
    border: 1px solid #BE161A;
    padding-left: 20%;
    background-size: 30% 100% !important;
}