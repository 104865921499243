.subTitle {
    border-radius: 10px;
    backdrop-filter: blur(6.4px);
    background-color: rgba(255, 255, 255, 0.1);
    height: auto;
    padding: 0px 15px;
}

.slick-bg1:before {
    background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(83, 100, 141, 0) 100%) !important;
    width: 100%;
    z-index: -1;
}

.ws-home-category {
    border-radius: 10px;
    backdrop-filter: blur(6.4px);
    background-color: rgba(255, 255, 255, 0.1);
    height: auto;
    padding: 20px 25px;
    margin-bottom: 20px;
}

.wd-card-line {
    height: 5px;
    width: 100px;
    background-color: #fff;
    margin: 20px 0px;
}
.slick-bgLessons{
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.slick-bgLessons:before {
    background: transparent !important;

}